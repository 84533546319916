export const userRightsHtmlContent = `
<!DOCTYPE html PUBLIC "-//W3C//DTD HTML 4.01 Transitional//EN" "http://www.w3.org/TR/html4/loose.dtd">
<html>
  <head>
    <title></title>
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
  </head>
  <body>
    <div style="page-break-before:always; page-break-after:always">
      <div>
        <p>AXE REDES E INFRAESTRUCTURA S.A DE C.V. <br />
        </p>
        <p>
          <b>
            Derecho de Usuarios
          </b>
        </p>
        <p>
            En AXE REDES estamos comprometidos contigo y en apego a lo establecido en la regulación aplicable hacemos pública la “Carta de Derechos Mínimos de los Usuarios", para servicios de telecomunicaciones prestados al amparo de un Contrato de Adhesión.
            <br />
        </p>
        <p>
            <br />
            CARTA DE DERECHOS MÍNIMOS DE LOS USUARIOS DE LOS SERVICIOS PÚBLICOS DE TELECOMUNICACIONES.
            <br />
        </p>
        <p>
            I. LIBERTAD DE ELEGIR
            <br />
        </p>
        <p>
            Tienes derecho a elegir:
            <br />
        </p>
        <p>
            1. El servicio, el proveedor y el equipo o dispositivo que desees contratar.
            <br />
            2. Paquete, plan o tarifa y forma de pago en prepago o pospago y en este último, decidir situ consumo será libre o controlado.
            <br />
        </p>
        <p>
            3. El o los servicios que desees contratar, sin que el proveedor te condicione o te obligue a contratar servicios o productos adicionales al principal. Para ello, el proveedor debe obtener tu consentimiento expreso.
            <br />
        </p>
        <p>
            4. No recibir llamadas publicitarias de tu proveedor, a menos de que exista tu consentimiento expreso.
            <br />
        </p>
        <p>
            5. El sistema de atención con los que cuenta el proveedor, para presentar tu queja, reportar anomalías del servicio, formular consultas, cancelar los servicios o cualquier trámite relacionado con estos.
            <br />
        </p>
        <p>
            II. ACCESO A LA INFORMACIÓN
            <br />
        </p>
        <p>
            Tienes derecho a que te informen a través de medios físicos o electrónicos o digitales o de cualquier otra tecnología que lo permita:
            <br />
        </p>
        <p>
            6. Las tarifas, planes y/o paquetes de los servicios, sus características y su número de registro ante el IFT, en un formato simplificado, así como cualquier otro cargo que se pueda efectuar por la prestación de los servicios.
            <br />
        </p>
        <p>
            7. El monto total a pagar por el servicio o producto que te ofrezcan, de manera notoria y visible.
            <br />
        </p>
        <p>
            Dicho monto deberá incluir impuestos, comisiones, intereses, seguros y cualquier otro costo, cargo, gasto o erogación adicional que se requiera cubrir con motivo de la adquisición o contratación respectiva, sea esta al contado o a crédito.
            <br />
        </p>
      </div>
    </div>
    <div style="page-break-before:always; page-break-after:always">
      <div>
        <p>
            8. Los requisitos y procedimientos de contratación de los servicios, así como los términos y condiciones del contrato tales como: penalidades, calidad, tarifa, opciones de pago, cancelación anticipada y políticas de uso, para la utilización de los servicios y la cobertura donde se presta el servicio antes de firmarlo.
            <br />
        </p>
        <p>
            La política de uso justo establece los criterios, condiciones, políticas, reglas, límites y restricciones que se aplicarán al uso de los servicios.
            <br />
        </p>
        <p>
            9. Tus derechos como usuario de servicios de telecomunicaciones y a que te entreguen esta Carta.
            <br />
        </p>
        <p>
            10. Las velocidades mínimas de Internet garantizadas.
            <br />
            11. Tu saldo y su vigencia de manera gratuita, así como las promociones y condiciones de uso.
            <br />
        </p>
        <p>
            12. Los medios para efectuar recargas de saldo, y una vez realizadas, el monto, fecha de expiración y la forma para consultar el saldo, así como la oferta comercial activada o beneficios incluidos.
            <br />
        </p>
        <p>
            13. Las opciones de los planes o paquetes de consumo controlado y libre, al contratar un servicio en pospago.
            <br />
        </p>
        <p>
            14. La notificación oportuna de que estás próximo a consumir lo incluido en tu plan o paquete, así como el costo por cargos adicionales en el caso de excederte en tu consumo contratado; ello cuando se trate de servicios de pospago abierto.
            <br />
        </p>
        <p>
            15. Si el equipo que adquieres está bloqueado y la forma de desbloquearlo en los servicios de telefonía.
            <br />
        </p>
        <p>
            16. Si requieres de un equipo en particular para acceder al servicio, o si ya cuentas con uno, si tiene las características mínimas necesarias para recibirlo. En caso de que no cuente con dichas características debe informar por escrito tal situación.
            <br />
        </p>
        <p>
            17. La ubicación de los centros de atención del proveedor y los medios para presentar quejas.
            <br />
        </p>
        <p>
            18. Tu consumo exacto con fecha y hora de corte de manera gratuita cuando así lo solicites.
            <br />
        </p>
        <p>
            19. En caso de que adquieras un equipo de forma financiada, el costo total, la mensualidad, el detalle de cualquier cargo adicional, intereses, número de pagos a realizar, la opción de liquidarlo anticipadamente y el derecho a desbloquearlo.
            <br />
        </p>
        <p>
            20. Los procedimientos expeditos (eficientes y rápidos) para solicitar la suspensión y/o cancelación de los servicios, así como el bloqueo del IMEI del equipo en caso de robo y/o extravío, de conformidad con la normatividad aplicable.
            <br />
        </p>
        <p>
            Una vez que reportaste tu equipo como robado o extraviado, el proveedor no podrá seguirte cobrando el servicio durante los 10 días naturales siguientes a tu reporte, siempre y cuando no hagas uso del servicio.
            <br />
        </p>
        <p>
            21. Previo a la contratación de los servicios adicionales proporcionados por el proveedor o servicios adicionales proporcionados por terceros, el costo, las características del servicio y los mecanismos disponibles para su cancelación.
            <br />
        </p>
        <p>
            22. Dónde puedes consultar el aviso de privacidad, mismo que debe establecer de manera clara la forma en que utilizarán tus datos personales.
            <br />
        </p>
        <p>
            23. Si eres usuario prepago, tu proveedor debe informarte a través del empaque que contiene tu chip o equipo terminal, la página de Internet en donde puedes consultar el contrato de adhesión que te aplica y, en su caso, la carátula, los mecanismos para manifestar el consentimiento, la mecánica de activación del servicio y la aceptación delos términos y condiciones.
            <br />
        </p>
      </div>
    </div>
    <div style="page-break-before:always; page-break-after:always">
      <div>
        <p>
            III. CONDICIONES DE CONTRATACIÓN, CLARAS, JUSTAS Y EQUITATIVAS
            <br />
        </p>
        <p>
            24. El contrato y su carátula deben estar en idioma español, con caracteres legibles a simple vista y contener el objeto del contrato, las principales características del servicio contratado y las condiciones aplicables, mismas que deberán ser equitativas y no discriminatorias.
            <br />
        </p>
        <p>
            25. Al momento de contratar tienes derecho a que se te presente la carátula del contrato de adhesión y te la entreguen por escrito.
            <br />
        </p>
        <p>
            El proveedor con el cual contrataste, es el único responsable de las obligaciones asumidas, por loque no puede trasladarlas a terceros.
            <br />
        </p>
        <p>
            26. El contrato de adhesión y su carátula deberán estar disponibles en la página de Internet de tu proveedor y contener cuando menos: la descripción del servicio que contratarás, las obligaciones de las partes, los términos y condiciones bajo las cuales se prestarán, tarifas registradas, las penas convencionales, los supuestos de terminación y modificación, los medios disponibles de atención a clientes y sus horarios.
            <br />
        </p>
        <p>
            El contrato también deberá contener las especificaciones técnicas y comerciales, incluyendo la calidad, la cual no deberá ser contraria a los parámetros definidos por el IFT y, en su caso, velocidad del servicio de telecomunicaciones contratada, así como las causales de suspensión o interrupción del servicio.
            <br />
        </p>
        <p>
            27. Tienes derecho a que se te indiquen los días y horas hábiles para la instalación, la cual no podrá ser mayor a 10 (diez) días hábiles. Para ello te debe entregar un folio para el seguimiento de la entrega y/o instalación del equipo y te debe indicar un rango de horario no mayor a 5 horas para efectuar la visita.
            <br />
        </p>
        <p>
            28. Cuando el proveedor te solicite una garantía para asegurar el cumplimiento de las obligaciones, si no tienes adeudo alguno al terminar tu contrato, tienes derecho a que el proveedor te la devuelva o la cancele. En consecuencia, te deberá informar de la existencia de la garantía que hayas otorgado, así como el mecanismo para la devolución de la misma.
            <br />
        </p>
        <p>
            29. Tienes derecho a exigir el cumplimiento forzoso del contrato de adhesión conforme a lo contratado o implícito en la publicidad o información publicada.
            <br />
        </p>
        <p>
            30. Tienes derecho a que en un plazo no mayor a 5 (cinco) días hábiles, tu proveedor de servicios te confirme lo que hayas contratado de manera verbal o electrónica.
            <br />
        </p>
        <p>
            Modificaciones del contrato.
        </p>
      </div>
    </div>
    <div style="page-break-before:always; page-break-after:always">
      <div>
        <p>
            31. Tienes derecho a que te notifiquen de cualquier cambio en las condiciones originalmente contratadas. El contrato únicamente se podrá modificar con tu consentimiento y previo aviso a través de medios físicos o de cualquier otra tecnología que lo permita, para lo cual deberá notificarte con al menos 15 (quince) días naturales de anticipación.
            <br />
        </p>
        <p>
            32. Tienes derecho a que te notifiquen la terminación anticipada del contrato con la finalidad de sustituirlo por otro nuevo. El proveedor debe obtener tu consentimiento expreso.
            <br />
        </p>
        <p>
            33. Si contratas a plazo forzoso, el proveedor no puede modificar el plazo, precio o tarifa contratada bajo ningún supuesto, a menos de que te genere un beneficio.
            <br />
        </p>
        <p>
            34. Tienes derecho a exigir el cumplimiento forzoso en los términos acordados o a solicitar dentro de los 30 (treinta) días naturales siguientes al aviso de modificación, la rescisión sin penalización alguna y con derecho a la bonificación correspondiente, cuando no hayas otorgado tu consentimiento expreso para modificarlo o sustituirlo. En este caso, el proveedor se hará acreedor a una penalización.
            <br />
        </p>
        <p>
            35. Tienes derecho a cambiarte de paquete o plan, aunque sea de menor monto, o cancelarlo de manera anticipada, pagando en su caso, el costo remanente de tu equipo o los cargos adicionales que se generen.
            <br />
        </p>
        <p>
            Contrato registrado ante PROFECO e inscrito en el IFT.
            <br />
        </p>
        <p>
            36. El contrato de adhesión debe estar autorizado y registrado ante la PROFECO y posteriormente inscrito ante el IFT, el cual deberá de coincidir con el que te entregue el proveedor y estar disponible en su portal de Internet. Cualquier alteración al mismo no será válida.
            <br />
        </p>
        <p>
            37. Tienes derecho a que el contrato de adhesión tenga condiciones claras, justas y equitativas como penas razonables por terminación anticipada, entre otros.
            <br />
        </p>
        <p>
            Vigencia. 
            <br />
        </p>
        <p>
            38. Tienes derecho a que el contrato establezca la vigencia y cuando esta sea a un plazo forzoso, los supuestos por los cuales se te obliga a dicho plazo.
            <br />
        </p>
        <p>
            En ningún caso el proveedor deberá obligarte a renovar tu contrato de plazo forzoso de manera automática.
            <br />
        </p>
        <p>
            39. En el esquema de prepago, no te podrán imponer plazo forzoso alguno.
            <br />
        </p>
        <p>
            40. En el esquema de prepago, tienes derecho a que te abonen en tu próxima recarga el saldo que no utilizaste durante el periodo de su vigencia, siempre y cuando la realices dentro del año siguiente y le sea aplicable al servicio.
            <br />
        </p>
        <p>
            Terminación del Contrato.
            <br />
        </p>
        <p>
            41. Tienes derecho a que se te informe sobre los requisitos y procedimientos detallados para la cancelación de los servicios y sobre cualquier cargo que se pueda generar por la terminación anticipada del contrato.
        </p>
      </div>
    </div>
    <div style="page-break-before:always; page-break-after:always">
      <div>
        <p>
            42. Tienes derecho a terminar el contrato de manera anticipada en cualquier momento y atendiendo las penas convencionales que correspondan mediante mecanismos expeditos(eficientes y rápidos), incluidos los medios físicos o electrónicos o digitales o de cualquier otra tecnología que lo permita, y que te sea otorgado un folio o registro de cancelación, que podrá ser entregado por el medio que solicites.
            <br />
        </p>
        <p>
            43. En el esquema de pospago, una vez concluido el plazo forzoso, puedes dar por terminado en cualquier momento el contrato sin necesidad de recabar la autorización del proveedor, lo cual no te libera de pagar tus adeudos.
            <br />
        </p>
        <p>
            44. En el esquema de pospago, es tu derecho que el proveedor de servicios te comunique de manera fehaciente, a través de la factura, mensaje de texto o medios físicos o electrónicos o digitales o de cualquier otra tecnología que lo permita, que el plazo forzoso está por concluir con al menos 30 (treinta) días naturales de anticipación.
            <br />
        </p>
        <p>
            45. Tienes derecho a negarte a la instalación o activación del servicio si el personal del proveedor no se identifica o muestra la orden de trabajo, sin responsabilidad alguna para ti.
            <br />
        </p>
        <p>
            46. Tienes derecho a cancelar tu contrato si el proveedor no instala o activa el servicio en los tiempos convenidos o establecidos, y a la devolución del monto total pagado por concepto de anticipo, depósito, instalación o renta dentro de los 10 (diez) días hábiles siguientes a tu solicitud de cancelación.
            <br />
        </p>
        <p>
            47. En el caso de prepago en el servicio móvil, tienes derecho a que el proveedor te describa en el contrato el ciclo de vida del número asignado para recibir el servicio, es decir, cuando se encuentre activo, se suspenda o se cancele.
            <br />
        </p>
        <p>
            Tu servicio solo podrá ser cancelado cuando no tengas saldo pendiente de ser abonado, o bien, si transcurren 365 (trescientos sesenta y cinco) días naturales a partir de la fecha de su vencimiento.
            <br />
        </p>
        <p>
            Penas razonables, proporcionales, recíprocas y equitativas.
            <br />
        </p>
        <p>
            48. Tienes derecho a que las penas por terminación anticipada y por suspensión temporal del servicio por falta de pago establecidas en el contrato de adhesión sean razonables, recíprocas, equitativas y proporcionales. Estas no deben superar el monto insoluto(adeudo pendiente) de la obligación principal. En ningún caso el proveedor podrá exigir doble penalización por el mismo incumplimiento.
            <br />
        </p>
        <p>
            Servicios adicionales.
            <br />
        </p>
        <p>
            Cuando solicites la contratación de servicios de costo adicional proporcionados por terceros, el proveedor debe obtener tu consentimiento expreso previamente e informarte:
            <br />
        </p>
        <p>
            49. Que la responsabilidad en la prestación de dicho servicio es exclusiva del tercero y te deberá indicar los datos del proveedor como: nombre comercial, domicilio y teléfono de atención, descripción de los servicios, tarifas vigentes, restricciones, términos, condiciones, limitaciones aplicables, procedimientos de cobro y facturación.
            <br />
            Que los cobros se realizarán con cargo a la factura del proveedor.
            <br />
            Los mecanismos disponibles para la cancelación del servicio.
            <br />
        </p>
      </div>
    </div>
    <div style="page-break-before:always; page-break-after:always">
      <div>
        <p>
            50. Si solicitas la cancelación de servicios adicionales proporcionados por el proveedor o servicios adicionales proporcionados por terceros, esta surtirá efectos a los 5 (cinco) días naturales posteriores a la solicitud sin que ello implique la cancelación del servicio principal.
            <br />
        </p>
        <p>
            Renovación del contrato.
            <br />
        </p>
        <p>
            51. Tienes derecho a que cuando renueves tu contrato de servicio y no adquieras un nuevo equipo o dispositivo, el pago esté integrado solamente por las tarifas registradas ante el IFT aplicables a los servicios que renueves sin contemplar monto alguno por el equipo. El proveedor debe integrar la factura o estado de cuenta únicamente con el costo de los servicios contratados.
            <br />
        </p>
        <p>
            Tarifas registradas ante el IFT.
            <br />
        </p>
        <p>
            52. Tienes derecho a que te respeten el precio o la tarifa contratada ofrecida o convenida y que coincida con la registrada y publicada ante el IFT.
            <br />
        </p>
        <p>
            53. Tienes derecho a que no se te incremente injustificadamente el precio por fenómenos naturales, meteorológicos o contingencias sanitarias.
            <br />
        </p>
        <p>
            Devoluciones y Bonificaciones.
            <br />
        </p>
        <p>
            54. En caso de cobros indebidos o haber hecho pagos en exceso, el proveedor deberá devolver la cantidad dentro de 5 (cinco) días hábiles siguientes a la reclamación.
            <br />
        </p>
        <p>
            Si no lo hace, además de la sanción que corresponda, estará obligado a pagar los intereses.
            <br />
        </p>
        <p>
            Tienes hasta un año para solicitar la devolución.
            <br />
        </p>
        <p>
            55. Tienes derecho a la bonificación o descuento por fallas en el servicio o cargos indebidos atribuibles al proveedor conforme a lo contratado o cuando así lo determine la autoridad.
            <br />
        </p>
        <p>
            56. Tienes derecho a que el proveedor te compense la parte proporcional del servicio, plan o paquete de telecomunicaciones que se dejó de prestar, y como bonificación, al menos el veinte por ciento del monto del periodo de afectación en la prestación del servicio de telecomunicaciones, cuando el proveedor no te preste el servicio conforme a lo contratado o implícito en la publicidad o porque no te preste el servicio por causas atribuibles al proveedor.
            <br />
        </p>
      </div>
    </div>
    <div style="page-break-before:always; page-break-after:always">
      <div>
        <p>
            El proveedor debe establecer en el contrato e informarte sobre los mecanismos para compensar y/o bonificar
            <br />
        </p>
        <p>
            IV. DESBLOQUEO DE EQUIPO TERMINAL
            <br />
        </p>
        <p>
            57. Tienes derecho a solicitar y obtener el desbloqueo del equipo terminal de manera gratuita sin que te exijan mayores requisitos que tu solicitud, cuando concluya la vigencia del contrato o se haya liquidado su costo o venza el plazo inicial de contratación o de financiamiento, debiéndote proporcionar la clave de desbloqueo.
            <br />
        </p>
        <p>
            Los proveedores de servicio deben contar con mecanismos ágiles que permitan realizar el desbloqueo del equipo o dispositivo en un plazo máximo de 24 horas, siempre y cuando la solicitud se haya realizado en un día y hora hábil.
            <br />
        </p>
        <p>
            Cuando se realice en día y hora inhábil, se inicia la cuenta a partir del siguiente día hábil. En caso de negativa, el proveedor debe informarte las causas por escrito.
            <br />
        </p>
        <p>
            V. PORTABILIDAD DEL NÚMERO TELEFÓNICO
            <br />
        </p>
        <p>
            58. Tienes derecho a cambiarte gratuitamente de compañía telefónica conservando tu número.
            <br />
        </p>
        <p>
            59. Tienes derecho a solicitar y recibir tu NIP de confirmación para realizar la portabilidad, su entrega no deberá condicionarse a tener saldo o que el servicio no se encuentre suspendido.
            <br />
        </p>
        <p>
            60. Tienes derecho a que la portabilidad se realice durante las 24 horas siguientes a tu solicitud o dentro de los siguientes 5 (cinco) días hábiles si así lo decides.
            <br />
        </p>
        <p>
            61. Es tu derecho que al solicitar la portabilidad numérica se cancele automáticamente el servicio de telefonía, pero no los demás servicios que tengas contratados.
            <br />
        </p>
        <p>
            Ningún contrato puede contener condiciones o prácticas que limiten tu derecho a cambiarte de compañía conservando tu mismo número.
            <br />
        </p>
        <p>
            62. En caso de que no se cumplan los plazos de portabilidad correspondientes, tienes derecho a recibir el pago de las penas convencionales previstas en el contrato.
            <br />
        </p>
        <p>
            63. Tienes derecho a acceder al sistema de información para consultar tu trámite de portabilidad y que el proveedor te otorgue la información necesaria para la consulta.
            <br />
        </p>
        <p>
            64. Al realizar tu portabilidad, tu anterior proveedor no podrá contactarte para retenerte u ofrecerte sus servicios.
            <br />
        </p>
        <p>
            65. Tienes derecho a cancelar la portabilidad en cualquier momento, o bien, en caso de que no se realice, la cancelación debe ser sin ninguna penalización.
            <br />
        </p>
        <p>
            66. Tienes derecho a solicitar la portabilidad aun si tienes adeudos con el proveedor, pero no te exenta de pagarlos y cubrir las penalidades que apliquen, así como a devolver los equipos que no sean de tu propiedad.
            <br /> 
        </p>
      </div>
    </div>
    <div style="page-break-before:always; page-break-after:always">
      <div>
        <p>
            67. La portabilidad sin tu consentimiento está prohibida, tienes derecho a solicitar a tu proveedor recupere tu número dentro de los 15 (quince) días hábiles siguientes a la fecha en que se portó sin tu consentimiento.
            <br />
        </p>
        <p>
            68. Tienes derecho a recibir orientación para realizar tu portabilidad o recuperación de tu número.
            <br />
        </p>
        <p>
            69. Cuando hayas cancelado tu servicio, tienes derecho a la recuperación de tu número telefónico dentro de los 40 (cuarenta) días naturales siguientes, para ello, debes acudir con tu nuevo proveedor.
            <br />
        </p>
        <p>
            70. Cuando hayas cancelado tu servicio, tienes derecho a la recuperación de tu número telefónico dentro de los 40 (cuarenta) días naturales siguientes, para ello, debes acudir con tu nuevo proveedor.
            <br />
        </p>
        <p>
            VI. EQUIPOS TERMINALES Y GARANTÍAS
            <br />
        </p>
        <p>
            Equipos terminales.
            <br />
        </p>
        <p>
            71. Tienes derecho a que el proveedor te informe si el equipo terminal de tu propiedad o el que él te proporcione se encuentran homologados conforme a las disposiciones jurídicas aplicables, así como a que te informe si el equipo terminal de tu propiedad es apto parala prestación de sus servicios.
            <br />
        </p>
        <p>
            Garantías.
            <br />
        </p>
        <p>
            72. Tienes derecho a que el equipo terminal nuevo o reconstruido que te proporcione el proveedor, cuente con una garantía no menor a 90 (noventa) días naturales, y contar conla capacidad técnica para repararlo.
            <br />
        </p>
        <p>
            73. Tienes derecho a que el proveedor o el fabricante, te entregue por escrito y en español, un documento conocido como póliza de garantía, la cual debe de contener: los conceptosque cubre, limitaciones o excepciones, así como los procedimientos para hacerla efectiva.
            <br />
        </p>
        <p>
            74. El proveedor deberá informarte si la vigencia de la garantía es menor al plazo forzoso y si el equipo falla fuera del periodo de garantía, deberá indicarte el proceso para repararlo.
            <br />
        </p>
        <p>
            Reparaciones.
            <br />
        </p>
        <p>
            75. Tienes derecho a que cuando el equipo sea reparado, no se descuente de la garantía el tiempo que dure la reparación y, en el caso de reposición del equipo, se renueve el plazo de la garantía. Cuando el bien haya sido reparado se iniciará la garantía respecto de las piezas repuestas y continuará con relación al resto.
            <br />
        </p>
        <p>
            76. Tienes derecho a que te suspendan el cobro del servicio contratado durante la revisión y reparación. No procede lo anterior, cuando el proveedor acredite que estás haciendo uso del servicio con un equipo propio o por uno proporcionado por el proveedor.
            <br />
        </p>
        <p>
            77. Si tu proveedor te proporcionó el equipo en arrendamiento o comodato debe hacerse responsable de las reparaciones durante el tiempo que dure el contrato, cuando las fallas no te sean atribuibles.
            <br />
        </p>
      </div>
    </div>
    <div style="page-break-before:always; page-break-after:always">
      <div>
        <p>
            Restitución de equipos.
            <br />
        </p>
        <p>
            78. Tienes derecho a la restitución del equipo terminal, la rescisión del contrato o la reducción del precio y, en cualquier caso, la bonificación o compensación, cuando el equipo terminal tenga defectos o vicios ocultos que hagan impropio su uso.
            <br />
        </p>
        <p>
            VII. ACCESIBILIDAD Y NO DISCRIMINACIÓN
            <br />
        </p>
        <p>
            Acceso en igualdad de condiciones.
            <br />
        </p>
        <p>
            79. Tienes derecho a que los proveedores te presten los servicios sin establecer privilegios o distinciones respecto de otros consumidores en la misma área de cobertura y en las mismas condiciones de contratación.
            <br />
        </p>
        <p>
            80. En la prestación de los servicios de telecomunicaciones estará prohibida toda discriminación motivada por origen étnico o nacional, género, edad, discapacidad, condición social, condiciones de salud, religión, opiniones, preferencias sexuales, estado civil o cualquier otra que atente contra la dignidad humana y tenga por objeto anular o menoscabar los derechos y libertades de las personas.
            <br />
        </p>
        <p>
            De las personas con discapacidad.
            <br />
        </p>
        <p>
            81. Tienes derecho a solicitar y recibir atención y asesoría en igualdad de condiciones que las demás personas usuarias sobre el uso de los servicios.
            <br />
        </p>
        <p>
            Para lo anterior, los proveedores te deberán ofrecer mecanismos que te permitan suscribir contratos, inconformidades u otros documentos sin que recaben tu firma autógrafa, ya sea con el uso de tu huella digital o que te asistas de un tercero debidamente acreditado.
            <br />
        </p>
        <p>
            Centros de atención.
            <br />
        </p>
        <p>
            82. Las instalaciones del proveedor deben contar con adaptaciones y personal capacitado para atender a personas con discapacidad.
            <br />
        </p>
        <p>
            El personal debe conocer al menos: los derechos de los usuarios con discapacidad, recomendaciones de atención por tipo de discapacidad e información de equipos terminales.
            <br />
        </p>
        <p>
            83. Tienes derecho a que los centros de atención al público de tu proveedor cuenten con lo siguiente:
            <br />
            Una ruta, área y señalización accesible.
            <br />
            Permitir el acceso acompañado por animales de servicio.
            <br />
            Áreas de espera con asientos prioritarios.
            <br />
            Personal capacitado.
            <br />
        </p>
      </div>
    </div>
    <div style="page-break-before:always; page-break-after:always">
      <div>
        <p>
            Información Accesible.
            <br />
        </p>
        <p>
            84. Tienes derecho a conocer las condiciones de los contratos y las tarifas de los servicios en formatos con funcionalidades de accesibilidad a través de las páginas de Internet de los proveedores o por correo electrónico cuando lo solicites.
            <br />
        </p>
        <p>
            85. Tienes derecho a solicitar a tu proveedor de servicios, te proporcione tus estados de cuenta con funcionalidades de accesibilidad.
            <br />
        </p>
        <p>
            Páginas de Internet Accesibles.
            <br />
        </p>
        <p>
            86. Tienes derecho a que los portales de Internet de los proveedores del servicio cuenten con funcionalidades de accesibilidad.
            <br />
        </p>
        <p>
            Equipos y Dispositivos Accesibles.
            <br />
        </p>
        <p>
            87. Tienes derecho a que los proveedores cuenten, una vez que así lo hayas solicitado, con equipos terminales con funcionalidades de accesibilidad para personas con discapacidad motriz, visual y auditiva, y que te proporcionen el equipo de tu elección dentro de los 15 (quince) días hábiles siguientes a tu solicitud.
            <br />
        </p>
        <p>
            El proveedor está obligado a contar con un catálogo de equipos terminales que cuenten con funcionalidades de accesibilidad.
            <br />
        </p>
        <p>
            VIII. PROTECCIÓN DE DATOS PERSONALES Y PRIVACIDAD
            <br />
        </p>
        <p>
            Datos Personales.
            <br />
        </p>
        <p>
            88. Tienes derecho a que el proveedor proteja tus datos personales en términos de las leyes aplicables.
            <br />
        </p>
        <p>
            Aviso de Privacidad.
            <br />
        </p>
        <p>
            89. Tienes derecho a que el proveedor ponga a tu disposición el Aviso de Privacidad por cualquier medio o tecnología que lo permita, así como indicarte donde lo puedes consultar.
            <br />
        </p>
        <p>
            En el esquema de prepago, al activar la línea, aceptas que el proveedor utilice tus datos personales de conformidad con su Aviso de Privacidad, a menos que con posterioridad y de manera expresa manifiestes lo contrario.
            <br />
        </p>
        <p>
            90. El proveedor solo podrá transferir tu información a terceros con tu consentimiento expreso, por lo que deberá poner a tu disposición el Aviso de Privacidad para que puedas ejercer tus derechos referidos en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares. Así también el proveedor solo podrá utilizar tu información con fines mercadotécnicos y publicitarios o para el envío de publicidad sobre bienes, productos o servicios, con tu consentimiento expreso. Este consentimiento estará expresado en el contrato de adhesión, en un documento previsto para tal efecto, o por el mismo medio por el cual se realizó la contratación o por medios electrónicos con posterioridad a la contratación.
        </p>
      </div>
    </div>
    <div style="page-break-before:always; page-break-after:always">
      <div>
        <p>
            Publicidad.
            <br />
        </p>
        <p>
            91. Solo con tu consentimiento podrás recibir llamadas de tu proveedor para promocionar servicios adicionales al contratado, paquetes, nuevo plan o producto, propio o de terceros, así como publicidad de terceros.
            <br />
        </p>
        <p>
            Si deseas revocar el consentimiento, el proveedor debe hacerlo en un tiempo máximo de 5 (cinco) días hábiles después de tu solicitud.
            <br />
        </p>
        <p>
            92. La información y publicidad que te proporcionen debe ser clara, comprobable, completa, veraz y no debe inducir a errores o confusiones.
            <br />
        </p>
        <p>
            93. Tienes derecho a que los anuncios de las promociones y ofertas de los servicios indiquen condiciones, plazo de duración o el volumen de los servicios ofrecidos. Si no indican plazo ni volumen se presume que son indefinidas.
            <br />
        </p>
        <p>
            IX. CALIDAD Y NEUTRALIDAD EN LA RED
            <br />
        </p>
        <p>
            Calidad en el Servicio.
            <br />
        </p>
        <p>
            94. Tienes derecho a recibir servicios de calidad conforme a los parámetros establecidos por el IFT o, en su caso, los ofrecidos implícitamente o contratados los cuales no pueden ser inferiores a los definidos por el IFT.
            <br />
        </p>
        <p>
            95. Tienes derecho a que se te provean los servicios con los parámetros de calidad definidos por el IFT.
            <br />
        </p>
        <p>
            Para el caso del servicio móvil en los servicios de voz, mensajería de texto y transferencia de datos se miden: los intentos de llamadas fallidas, llamadas interrumpidas, tiempo de establecimiento de llamadas, integridad de mensajes, velocidad de datos promedio de carga y descarga, entre otros.
            <br />
        </p>
        <p>
            Para el caso de servicio fijo, en la telefonía y el acceso a Internet se miden: los intentos exitosos de llamadas, el tiempo promedio de establecimiento de llamada, la velocidad de datos promedio de carga y descarga, entre otros. Asimismo, se miden las fallas, reparaciones y el tiempo promedio de reparación.
            <br />
        </p>
        <p>
            En los servicios de Internet, tienes derecho a que la velocidad publicitada no debe referirse a velocidades máximas sino a la velocidad promedio en horas de alta demanda.
            <br />
        </p>
        <p>
            Neutralidad de la Red.
            <br />
        </p>
      </div>
    </div>
    <div style="page-break-before:always; page-break-after:always">
      <div>
        <p>
            96. Tienes derecho a acceder sin limitación, degradación, restricción o discriminación a contenidos, aplicaciones o servicios ofrecidos por el proveedor, dentro del marco legal aplicable.
            <br />
        </p>
        <p>
            97. Tienes derecho a que tu proveedor no obstruya, interfiera, inspeccione, filtre o discrimine contenidos, aplicaciones o servicios.
            <br />
        </p>
        <p>
            98. Tienes derecho a la protección de tus comunicaciones, así como los datos que identifiquen las mismas y que el proveedor preserve tu privacidad y la seguridad de la red.
            <br />
        </p>
        <p>
            99. Tienes derecho a recibir la capacidad, velocidad y calidad que contrataste con independencia del contenido, origen, destino, terminal, aplicación o servicio que se te provean a través de Internet.
            <br />
        </p>
        <p>
            X. FACTURACIÓN
            <br />
        </p>
        <p>
            100. Tienes derecho a que tu proveedor te entregue gratuitamente, de forma desglosada, a través de medios físicos o electrónicos: factura, estado de cuenta, recibo o comprobante de los servicios de telecomunicaciones y, en su caso, de los equipos terminales adquiridos.
            <br />
        </p>
        <p>
            101. Tienes derecho a que el estado de cuenta, recibo y/o factura que te entregue el proveedor esté desglosado en lo correspondiente a tus consumos, bonificaciones o devoluciones, y cuando adquieras un equipo terminal de contado o financiado, se debe incluir en la factura la compra de dicho equipo.
            <br />
        </p>
        <p>
            Tu proveedor no puede cobrarte tarifas o conceptos diferentes a los que originalmente contrataste o aceptaste expresamente.
            <br />
        </p>
        <p>
            102. Para el esquema de pospago, se podrá consultar la factura, estado de cuenta, así como el recibo o comprobante, por cualquier medio que acuerden las partes, y el proveedor deberá enviarlo cuando menos con 10 (diez) días naturales antes de la fecha de pago y no podrá modificar el ciclo de facturación sin previo aviso.
            <br />
        </p>
        <p>
            En el estado de cuenta, recibo y/o factura que te entregue el proveedor, debe desglosar el costo del servicio y el costo del equipo, además de incluir en ellos cada uno de tus consumos y cargos, tales como: fecha y hora de llamadas, su duración en minutos o segundos, megas, mensajes, llamadas de larga distancia internacional, equipos terminales, servicios adicionales, y demás cargos realizados, así como la fecha de corte y lugares de pago.
            <br />
        </p>
        <p>
            103. Para el esquema de prepago, el proveedor te debe informar dónde consultar el estado de cuenta o detalle de consumos el cual debe contener la descripción de los cargos, costos, conceptos y naturaleza de los servicios prestados. La entrega del estado de cuenta es a solicitud del usuario.
            <br />
        </p>
        <p>
            Suspensión del Servicio.
        </p>
      </div>
    </div>
    <div style="page-break-before:always; page-break-after:always">
      <div>
        <p>
            104. Tienes derecho a que el proveedor te notifique antes de la suspensión de tu servicio y la razón por la que se realiza dicha suspensión.
            <br />
        </p>
        <p>
            105. En caso de falta de pago de servicios adicionales, tu proveedor no debe suspender el servicio de telecomunicaciones principal .
            <br />
        </p>
        <p>
            106. El servicio no podrá ser interrumpido o suspendido si se encuentra en trámite una queja.
            <br />
        </p>
        <p>
            107. Cuando estés al corriente con tus pagos y el proveedor suspenda de forma indebida el servicio, tienes derecho a que se te devuelva el monto por el servicio no prestado y a una bonificación de al menos el veinte por ciento.
            <br />
        </p>
        <p>
            Restablecimiento del Servicio y Reconexión.
            <br />
        </p>
        <p>
            108. En caso de suspensión por falta de pago, el proveedor deberá reanudar el servicio de telecomunicaciones en un periodo máximo de 48 horas a partir de que hayas realizado el pago o un máximo de 72 horas cuando la reconexión requiera de personal técnico. En algunos casos aplican cargos por reconexión.
            <br />
        </p>
        <p>
            Cobro por Duración de las Comunicaciones.
            <br />
        </p>
        <p>
            109. Tienes derecho a que en los servicios medidos por duración de las comunicaciones,el proveedor te ofrezca la opción de cobro por segundo, sin perjuicio de que también teofrezca la opción de cobro por minuto, por evento, por capacidad o cualquier otra modalidad.
            <br />
        </p>
        <p>
            XI. ROBO Y EXTRAVÍO – SERVICIOS DE EMERGENCIA
            <br />
        </p>
        <p>
            Robo y Extravío.
            <br />
        </p>
        <p>
            110. Tienes derecho a acceder a un mecanismo expedito para reportar el robo o extravío de tu equipo y a reportar la duplicación del IMEI de tu equipo, por lo que consecuentemente tienes derecho a solicitar la suspensión y/o cancelación del servicio, así como el bloqueo o la suspensión del IMEI; ello en el caso de equipos móviles.
            <br />
        </p>
        <p> 
            111. Tienes derecho a consultar si un equipo terminal móvil se encuentra reportado como robado o extraviado y por ello no deberá ser activado en ninguna de las redes delos operadores.
            <br />
        </p>
        <p>
            112. Tienes derecho a que una vez que reportaste tu equipo como robado o extraviado, el proveedor no podrá seguirte cobrando el servicio durante los 10 (diez) días naturales siguientes a tu reporte, siempre y cuando no hagas uso del servicio.
            <br />
        </p>
        <p>
            Servicios de Emergencia.
            <br />
        </p>
        <p>
            113. Tienes derecho a acceder gratuitamente al número de emergencia 911 el cual permitirá identificar y ubicar geográficamente tu llamada y, en su caso, los mensajes de texto que se envíen, para ser auxiliado.
            <br />
        </p>
        <p>
            XII. ATENCIÓN GRATUITA DEL PROVEEDOR
        </p>
      </div>
    </div>
    <div style="page-break-before:always; page-break-after:always">
      <div>
        <p>
            114. Tienes derecho a acceder gratuitamente, las 24 horas del día, todos los días del año, a un sistema de atención telefónica o electrónico del proveedor, en el que podrás realizar cualquier tipo de consulta, trámites, presentar dudas, aclaraciones, cancelaciones y reclamaciones sobre el servicio que se te proporciona por el medio que tu decidas.
            <br />
        </p>
        <p>
            115. Cuando elijas hablar con un representante del proveedor en los sistemas de atención telefónica, tienes derecho a que el tiempo de espera para que te atiendan no exceda de 60 segundos, salvo en casos fortuitos o de fuerza mayor.
            <br />
        </p>
        <p>
            116. Tienes derecho a ser informado del procedimiento y estado de los trámites que has realizado ante el proveedor, en el momento en el que lo solicites, respetándose los tiempos promedio y máximos de atención.
            <br />
        </p>
        <p>
            117. Tienes derecho a recibir atención de personal debidamente capacitado y que el proveedor te garantice que la información y orientación que requieres es certera.
            <br />
        </p>
        <p>
            118. Tienes derecho a recibir una constancia o folio de las reclamaciones, solicitudes o trámites que realices y a acceder a un sistema del proveedor que te permita conocer su estatus.
            <br />
        </p>
        <p>
            119. En el caso de las quejas recibidas en los sistemas de atención del proveedor, este deberá de atender las mismas en un plazo no mayor a 15 (quince) días naturales, salvo que exista un tiempo máximo establecido en otra normatividad.
            <br />
        </p>
        <p>
            Cuando hayas presentado una queja vía telefónica, o por vía electrónica (chat en línea o correo electrónico), tienes derecho a recibir un documento que acredite la presentación y contenido dela reclamación, incidencia o gestión.
            <br />
        </p>
        <p>
            XIII. TELEVISIÓN DE PAGA
            <br />
        </p>
        <p>
            Recibir Gratuitamente las Señales de Televisión Abierta (TV Abierta).
            <br />
        </p>
        <p>
            120. Tienes derecho a recibir gratuitamente las señales de los canales de TV Abierta que se radiodifundan dentro de tu zona de cobertura geográfica y, las señales de los canales transmitidos por Instituciones Públicas Federales, si tu servicio es de televisión de paga.
            <br />
        </p>
        <p>
            Bloqueo de Aplicaciones o Servicios.
            <br />
        </p>
        <p>
            121. Tienes derecho a que los proveedores bloqueen los contenidos, aplicaciones o servicios que solicites. Podrás realizar esta petición en los sistemas de atención del proveedor.
            <br />
        </p>
        <p>
            Control Parental.
            <br />
        </p>
        <p>
            122. Tienes derecho a que los proveedores te proporcionen un servicio de control parental previa solicitud de tu parte, y que publiquen de manera clara las características operativas de este servicio y las instrucciones para que puedas operar las aplicaciones necesarias para su correcto funcionamiento.
            <br />
        </p>
      </div>
    </div>
    <div style="page-break-before:always; page-break-after:always">
      <div>
        <p>
            XIV. PROTECCIÓN DE LAS INSTITUCIONES
            <br />
        </p>
        <p>
            123. Tienes derecho a la protección y representación de la PROFECO y del IFT, en el ámbito de sus respectivas atribuciones, para asegurar que tus derechos sean respetados, con vistas a la prevención de daños patrimoniales individuales y/o colectivos, garantizando tu protección jurídica, económica, administrativa y técnica.
            <br />
        </p>
        <p>
            La PROFECO y el IFT podrán efectuar las verificaciones y procedimientos correspondientes, en el ámbito de sus atribuciones y, en su caso, iniciar un procedimiento de sanción.
            <br />
        </p>
        <p>
            124. Tienes derecho a que la PROFECO, promueva, proteja, asesore, defienda, concilie y te represente frente a tu proveedor cuando presentes una queja o denuncia por el incumplimiento de alguna de las cláusulas contenidas en el contrato de adhesión.
            <br />
        </p>
        <p>
            125. Tienes derecho a presentar una queja ante el Instituto Federal deTelecomunicaciones por fallas en la calidad de los servicios o incumplimientos de los operadores.
            <br />
        </p>
        <p>
            Lo anterior, porque el IFT regula, monitorea y vigila la calidad de los servicios o incumplimientos de los operadores (concesionarios, autorizados o proveedores) a sus obligaciones.
            <br />
        </p>
        <p>
            Representación Colectiva.
            <br />
        </p>
        <p>
            126. Tienes derecho a ser representado en una acción colectiva ante el incumplimiento de un proveedor.
            <br />
        </p>
        <p>
            Para buscar que se declare que el proveedor incurrió en conductas que dañaron tus intereses y tu patrimonio y que lo condenen a la indemnización de daños y perjuicios. El representante detal colectividad o grupo puede ser PROFECO, una asociación de consumidores registrada o bien un grupo de por lo menos 30 consumidores afectados que firmen la demanda u otorguen su consentimiento para ser representados gratuitamente por la PROFECO.
            <br />
        </p>
        <p></p>
      </div>
    </div>
  </body>
</html>
`;
