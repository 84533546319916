import React from "react";
import BasicCard from "components/atoms/BasicCard";
import { removeSessionItem } from "utils/session";
import { removeLocalItem } from "utils/localStorage";
import { useNavigate } from "react-router-dom";
import QRCodeGenerator from "components/atoms/QrCodeGenerator";
import logo from "../../assets/images/coverage-xtremito.png";
// Función para identificar Mes
function getMonth(month: any) {
  const months = {
    1: "Enero",
    "01": "Enero",
    2: "Febrero",
    "02": "Febrero",
    3: "Marzo",
    "03": "Marzo",
    4: "Abril",
    "04": "Abril",
    5: "Mayo",
    "05": "Mayo",
    6: "Junio",
    "06": "Junio",
    7: "Julio",
    "07": "Julio",
    8: "Agosto",
    "08": "Agosto",
    9: "Septiembre",
    "09": "Septiembre",
    10: "Octubre",
    11: "Noviembre",
    12: "Diciembre",
  };

  return months[month as keyof typeof months];
}
export default function FutureCoveragePage() {
  const navigate = useNavigate();
  React.useEffect(() => {
    removeSessionItem("expirationDate");
    removeLocalItem("timer");
    navigate("/future-coverage");
  }, []);

  return (
    <div className="flex justify-center h-screen m-6 mb-10 lg:m-20">
      <div className="w-full sm:w-2/4 text-center">
        <div className="mt-10 mb-5">
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12 lg:col-span-4 flex justify-center">
              <img
                src={logo}
                alt=""
                style={{
                  width: 150,
                }}
                className="mx-auto"
              />
            </div>
            <div className="col-span-12 lg:col-span-8">
              <h3 className="text-4xl font-semibold secondary_text">
                ¡Excelentes Noticias!
              </h3>
              <p className="text-lg">
                En estos momentos estamos construyendo la red de X-tremo en tu
                zona.
              </p>
            </div>
          </div>
        </div>
        <div
          className="w-full lg:w-full text-white font-bold py-10 px-5 mt-3"
          style={{
            backgroundColor: "#00063d",
            color: "white",
            border: "#00063d 2px solid",
            borderRadius: "15px",
          }}
        >
          <h4 className="text-2xl mb-5" style={{ color: "#ebaa01" }}>
            {/* A partir del mes de{" "}<strong>{getMonth(sessionStorage.getItem("month") || "")}</strong>{" "}estaremos listos para ofrecerte X-tremo en tu domicilio. */}
            Próximamente estaremos listos para ofrecerte X-tremo en tu domicilio.
          </h4>
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12 lg:col-span-6">
              <h5 className="text-lg font-semibold secondary_text mb-3">
                ¡No te preocupes, ya tenemos tus datos!
              </h5>
              <p className="text-md mb-2">
                Una vez que esté disponible el servicio en tu domicilio nos
                pondremos en contacto contigo.
              </p>
            </div>
            <div className="col-span-12 lg:col-span-6">
              <h5 className="text-lg font-semibold secondary_text mb-3">
                ¿Necesitas ayuda?
              </h5>
              <p className="text-md mb-2">
                Escanea el QR a continuación y uno de nuestros representantes se
                comunicará contigo en breve.
              </p>
              <div className="mt-5">
                <div className="hidden md:block lg:block xl:block">
                  <QRCodeGenerator urlCode={`https://wa.me/${process.env.REACT_APP_XTREMO_WHATSAPP_NUMBER}?text=Necesito%20soporte%20de%20ventas`} />
                </div>

                <a
                  className="sm:block md:hidden lg:hidden xl:hidden"
                  href={`https://wa.me/${process.env.REACT_APP_XTREMO_WHATSAPP_NUMBER}?text=Necesito%20soporte%20de%20ventas`}
                  target="_blank"
                >
                  <button className=" w-full fleXx items-center justify-center bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                    Contactar un Asesor
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <button
          type="submit"
          className=" w-full lg:w-full flex items-center justify-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mt-3 rounded disabled:opacity-50 primary-btn"
          onClick={() => {
            navigate("/coverage");
          }}
        >
          REGRESAR
        </button>
      </div>
    </div>
  );
}
