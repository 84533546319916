export const PACKAGES = [
  {
    name: "Paquete Hogar Conectado",
    features: ["300 Megas Simétricos", "2 Ruteadores WiFi"],
    coupon: {
      couponId: "YvVvlqDc",
      name: "-50% por 3 meses",
    },
    products: [
      {
        productId: "prod_OIbryjku23ADCM",
        name: "Test - Paquete Hogar Conectado",
        price_data: {
          price_id: "price_1Ne3THDstokY9kirjjekNVNr",
          amount: "740.00",
        },
        description: "300 M, max 2 AP",
        metadata: {
          bandwidth: "300",
          type: "INTERNET",
        },

        included: 1,
      },

      {
        productId: "prod_OJ3I9bQvqq9ZAY",

        name: "Test - Equipo WiFi",

        price_data: {
          price_id: "price_1Ni2uYDstokY9kirys1hEXAu",

          amount: "79.00",
        },

        description: "Access Point",

        metadata: {
          type: "WIFI_DEVICE",
        },

        included: 2,
      },
    ],

    total_amount: "370.00",
  },
];

export const htmlContent = `
<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" xml:lang="es" lang="es">
  <head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <title>file_1693954049580</title>
    <meta name="author" content="David Inguanzo Vieyra" />
    <style type="text/css">
      * {
        margin: 0;
        padding: 0;
        text-indent: 0;
      }

      .s1 {
        color: #1F3863;
        font-family: Arial, sans-serif;
        font-style: normal;
        font-weight: bold;
        text-decoration: none;
        font-size: 13.5pt;
      }

      p {
        color: #62626C;
        font-family: Arial, sans-serif;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 12pt;
        margin: 0pt;
      }

      h1 {
        color: #828282;
        font-family: Arial, sans-serif;
        font-style: normal;
        font-weight: bold;
        text-decoration: none;
        font-size: 13.5pt;
      }

      .a,
      a {
        color: #62626C;
        font-family: Arial, sans-serif;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 12pt;
      }

      h2 {
        color: #62626C;
        font-family: Arial, sans-serif;
        font-style: normal;
        font-weight: bold;
        text-decoration: none;
        font-size: 12pt;
      }

      li {
        display: block;
      }

      #l1 {
        padding-left: 0pt;
        counter-reset: c1 1;
      }

      #l1>li>*:first-child:before {
        counter-increment: c1;
        content: counter(c1, decimal)". ";
        color: #62626C;
        font-family: Arial, sans-serif;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 12pt;
      }

      #l1>li:first-child>*:first-child:before {
        counter-increment: c1 0;
      }

      #l2 {
        padding-left: 0pt;
      }

      #l2>li>*:first-child:before {
        content: "• ";
        color: #62626C;
        font-family: Arial, sans-serif;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 12pt;
      }

      #l3 {
        padding-left: 0pt;
      }

      #l3>li>*:first-child:before {
        content: "• ";
        color: #62626C;
        font-family: Arial, sans-serif;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 12pt;
      }

      #l4 {
        padding-left: 0pt;
        counter-reset: e1 1;
      }

      #l4>li>*:first-child:before {
        counter-increment: e1;
        content: counter(e1, lower-latin)") ";
        color: #62626C;
        font-family: Arial, sans-serif;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 12pt;
      }

      #l4>li:first-child>*:first-child:before {
        counter-increment: e1 0;
      }

      #l5 {
        padding-left: 0pt;
        counter-reset: f1 1;
      }

      #l5>li>*:first-child:before {
        counter-increment: f1;
        content: counter(f1, lower-latin)") ";
        color: #62626C;
        font-family: Arial, sans-serif;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 12pt;
      }

      #l5>li:first-child>*:first-child:before {
        counter-increment: f1 0;
      }

      #l6 {
        padding-left: 0pt;
      }

      #l6>li>*:first-child:before {
        content: "• ";
        color: #62626C;
        font-family: Arial, sans-serif;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 12pt;
      }

      #l7 {
        padding-left: 0pt;
        counter-reset: h1 1;
      }

      #l7>li>*:first-child:before {
        counter-increment: h1;
        content: counter(h1, lower-latin)") ";
        color: #62626C;
        font-family: Arial, sans-serif;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 12pt;
      }

      #l7>li:first-child>*:first-child:before {
        counter-increment: h1 0;
      }
    </style>
  </head>
  <body>
    <p style="text-indent: 0pt;text-align: left;">
      <br />
    </p>
    <p class="s1" style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">
      Aviso de Privacidad
    </p>
    <p style="text-indent: 0pt;text-align: left;">
      <br />
    </p>
    <p style="padding-top: 12pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">Aviso de Privacidad Integral</p>
    <p style="text-indent: 0pt;text-align: left;">
      <br />
    </p>
    <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">El presente documento constituye el Aviso de Privacidad de AXE REDES E INFRAESTRUCTURA S.A DE C.V. (en lo sucesivo “X-tremo”).</p>
    <p style="text-indent: 0pt;text-align: left;">
      <br />
    </p>
    <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
        X-tremo, en cumplimiento al principio de información establecido en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (en lo sucesivo la Ley) art. 8, 15, 16, 17, fracción II, 33, y 36; su Reglamento art. 14, 24, 26, 27, 28, 30, 40, 41, 42, 68, 90 y 102, y los Lineamientos del Aviso de Privacidad 10º, 18º, 20º, 21º, 22º, 23º, 24º, 25º, 26º, 27º, 28º, 29º, 30º, 31º, 32º, 33º, 37º y 41º, hace de su conocimiento la existencia y características principales del tratamiento al que serán sometidos sus datos personales de acuerdo a lo que a continuación se señala.
    </p>
    <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
        X-tremo respeta su derecho a la privacidad y protección de datos personales y/o datos biométricos; por tanto, su información se encuentra debidamente resguardada conforme a las disposiciones de seguridad administrativa, técnica y física, establecidas en la Ley de la materia, para protegerla de los posibles daños, perdidas, alteración o acceso no autorizado.
    </p>
    <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
        X-tremo, con domicilio en: Av. Múnich 175, Col. Cuauhtémoc, San Nicolás de los Garza, Nuevo León, México, C.P. 66450, es la responsable de la base de datos en donde consta su información.
    </p>
    <p style="text-indent: 0pt;text-align: left;">
      <br />
    </p>
    <ol id="l1">
      <li data-list-text="1.">
        <p style="padding-left: 41pt;text-indent: -18pt;text-align: left;">
            Datos personales que utilizaremos
        </p>
        <p style="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">
            X-tremo, obtiene los datos personales del titular en forma directa y/o través de medios físicos, electrónicos o presenciales, así como de fuentes públicas autorizadas, con el propósito de cumplir con las finalidades principales y necesarias señaladas en el presente Aviso de Privacidad. Los Datos personales a los que se les dará tratamiento de acuerdo con el tipo de titular son:
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br />
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
            Clientes y/o Proveedores: datos de Identificación, datos fiscales, datos de contacto, datos financieros y biométricos. En el caso de clientes y/o proveedores no se da tratamiento a datos personales sensibles.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br />
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
            Candidatos, Empleados y/o Servicios Profesionales: datos de Identificación, datos de contacto, datos académicos, datos laborales. No se da tratamiento a datos personales sensibles.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br />
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">
            Contratistas: datos de identificación, datos de contacto, datos laborales, datos que comprueben la vigencia de derechos en materia de seguridad social o seguros de gastos médicos mayores. No se recaban datos personales sensibles.
        </p>
        <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">
            Visitantes de portales en Internet: datos de identificación y datos de contacto. No se recaban datos personales sensibles.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br />
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
            Ingreso a las instalaciones: datos de identificación, datos de contacto, en su caso empresa que representa y motivo de la visita. No se da tratamiento a datos personales sensibles.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br />
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
            No será necesario el consentimiento para el tratamiento de los Datos Personales cuando:
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br />
        </p>
        <ul id="l2">
          <li data-list-text="•">
            <p style="padding-left: 12pt;text-indent: -7pt;text-align: left;">
                Esté previsto por Ley;
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 12pt;text-indent: -7pt;text-align: left;">
                Los datos figuren en fuentes de acceso público;
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 12pt;text-indent: -7pt;text-align: left;">
                Los Datos Personales se sometan a un procedimiento previo de disociación;
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
                Tenga el propósito de cumplir obligaciones derivadas de una relación jurídica entre el Titular de los Datos Personales y X-tremo;
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
                Exista una situación de emergencia que potencialmente pueda dañar a un individuo en sus bienes o persona;
            </p>
            <p style="text-indent: 0pt;text-align: left;">
              <br />
            </p>
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
                En caso de declaración de autoridad, si hubiere algún afectado, a falta del consentimiento previo y por escrito de este, X-tremo deberá proteger su identificación, salvo que exista una razón justificada y determinada por el área de recursos humanos para hacerlo.
            </p>
            <p style="text-indent: 0pt;text-align: left;">
              <br />
            </p>
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
                De ser preciso, no será necesario el consentimiento para el tratamiento de datos personales sensibles. cuando:
            </p>
            <p style="text-indent: 0pt;text-align: left;">
              <br />
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
                Se deba cumplir con la seguridad, higiene y prevención de riesgos en centros de trabajo de conformidad con la Ley Federal del Trabajo, NOM´S, Reglamentos interiores, políticas y procesos de X-tremo para atender situaciones de extrema urgencia; o
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 12pt;text-indent: -7pt;text-align: left;">
                Se dicte resolución de autoridad competente.
            </p>
            <p style="text-indent: 0pt;text-align: left;">
              <br />
            </p>
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
                En todos los casos, es nuestro compromiso que los mismos serán tratados bajo estrictas medidas de seguridad, siempre garantizando su confidencialidad.
            </p>
            <p style="text-indent: 0pt;text-align: left;">
              <br />
            </p>
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
                En caso de proporcionar cualquier tipo de dato personal relacionado con otra persona como referencia, para efectos del presente aviso de privacidad, usted declara y acepta que ha obtenido el consentimiento previo correspondiente de dicha persona para ello.
            </p>
            <p style="text-indent: 0pt;text-align: left;">
              <br />
            </p>
          </li>
        </ul>
      </li>
      <li data-list-text="2.">
        <p style="padding-left: 41pt;text-indent: -18pt;text-align: left;">
            Finalidades de uso de sus datos personales.
        </p>
        <p style="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">
            Las finalidades principales y/o necesarias por las cuales X-tremo puede solicitar, obtener, almacenar y/o utilizar los Datos Personales por tipo de Titular son:
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br />
        </p>
        <h1 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
            Clientes y Proveedores:
        </h1>
        <ul id="l3">
          <li data-list-text="•">
            <p style="padding-top: 4pt;padding-left: 12pt;text-indent: -7pt;text-align: left;">
                Verificar y comprobar su identidad,
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
                Elaborar propuestas y cotizaciones para contratación y, envió de información en virtud de la relación comercial,
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 12pt;text-indent: -7pt;text-align: left;">
                Llevar a cabo las gestiones necesarias para la relación jurídica,
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
                Contratar servicios necesarios para entrega de las contraprestaciones económicas y en especie establecidas en los contratos,
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 12pt;text-indent: -7pt;text-align: left;">
                Con fines estadísticos, elaboración de reportes y actualización de la base de datos,
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 12pt;text-indent: -7pt;text-align: left;">r
                Realizar todos los trámites ante las autoridades correspondientes,
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 12pt;text-indent: -7pt;text-align: left;">
                Gestión de pagos y cobranza de servicios contratados, y
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 12pt;text-indent: -7pt;text-align: left;">
                Evaluación de la calidad y satisfacción de los productos y servicios.
            </p>
            <p style="text-indent: 0pt;text-align: left;">
              <br />
            </p>
            <h1 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
                Candidatos, Empleados y/o Servicios Profesionales:
            </h1>
            <p style="text-indent: 0pt;text-align: left;">
              <br />
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 12pt;text-indent: -7pt;text-align: left;">
                Verificar y comprobar su identidad,
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
                Evaluación y control interno de conocimientos y habilidades para su contratación laboral,
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
                Administración de recursos humanos, gestión de talento y actualizar base de datos y expedientes,
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
                Contratar servicios para entrega de las contraprestaciones económicas y en especie establecidas en los contratos,
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 12pt;text-indent: -7pt;text-align: left;">
                Llevar a cabo las gestiones necesarias para la relación jurídica,
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 12pt;text-indent: -7pt;text-align: left;">
                Comunicar a sus contactos cuando así lo amerite
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 12pt;text-indent: -7pt;text-align: left;">
                Realizar los trámites ante las autoridades correspondientes,
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 12pt;text-indent: -7pt;text-align: left;">
                Llevar control de programas de salud en el trabajo,
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
                Elaboración de documentos, contratos, convenios, facturas, recibos, reconocimientos y en general cualquier documento relacionado con la relación laboral,
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
                Transferencia de datos a autoridades de salud: documentada claramente. En caso de contingencia sanitaria X-tremo podrá dar tratamiento a los datos de salud para tomar las precauciones que se determinen para proteger y para dar continuidad a las actividades laborales.
            </p>
            <p style="text-indent: 0pt;text-align: left;">
              <br />
            </p>
            <h1 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
                Contratistas:
            </h1>
            <p style="text-indent: 0pt;text-align: left;">
              <br />
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 12pt;text-indent: -7pt;text-align: left;">
                Monitoreo y control de su visita, así como para permitir el acceso a las instalaciones.
            </p>
            <p style="text-indent: 0pt;text-align: left;">
              <br />
            </p>
            <h1 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
                Visitantes de portales en Internet:
            </h1>
            <p style="text-indent: 0pt;text-align: left;">
              <br />
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
                Atender cualquier solicitud de información o servicio a través de las opciones de contacto disponibles nuestras páginas electrónicas en internet.
            </p>
            <p style="text-indent: 0pt;text-align: left;">
              <br />
            </p>
            <h1 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
                Ingreso a nuestras instalaciones:
            </h1>
            <p style="text-indent: 0pt;text-align: left;">
              <br />
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 12pt;text-indent: -7pt;text-align: left;">
                Monitoreo y control de su visita, así como permitir el acceso a las instalaciones.
            </p>
          </li>
        </ul>
        <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">
            De manera adicional, para cualquier titular, X-tremo, como finalidad secundaria puede solicitar, obtener, almacenar y/o utilizar los Datos Personales para:
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br />
        </p>
        <h1 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
            la comunicación, promoción y difusión a través de medios físicos y electrónicos, de nuestros productos y/o servicios.
        </h1>
        <p style="text-indent: 0pt;text-align: left;">
          <br />
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
            En caso de que no desee que sus datos personales sean tratados para estos fines adicionales, desde este momento usted lo puede comunicar a X-tremo. En ningún caso se dará un uso distinto a los aquí señalados a cualquier Dato Personal recabado, salvo que medie un cambio en este Aviso de Privacidad.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br />
        </p>
      </li>
      <li data-list-text="3.">
        <p style="padding-left: 41pt;text-indent: -18pt;text-align: left;">
            Transferencia de Datos Personales (con quien compartimos).
        </p>
        <p style="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">
            X-tremo como parte del tratamiento de datos personales, no realiza transferencias de Datos Personales Sensibles, Patrimoniales o Financieros. En caso de requerirse, la misma no se realizará sin previo consentimiento expreso y por escrito por parte del Titular, a excepción de las especificadas en las finalidades del uso.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br />
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
            Los Datos Personales pueden ser transferidos lícitamente para las finalidades establecidas en el presente Aviso de Privacidad a:
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br />
        </p>
        <ol id="l4">
          <li data-list-text="a)">
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
                Las sociedades subsidiarias, filiales, afiliadas, controladoras, y/o contraladas de X-tremo dentro del territorio nacional o en el extranjero,
            </p>
          </li>
          <li data-list-text="b)">
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
                Cuando la transferencia sea necesaria para proveer de los productos y servicios o dar cumplimiento a las relaciones laborales o comerciales contratadas.
            </p>
          </li>
          <li data-list-text="c)">
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
                A terceros en materia de atención de clientes y estudios relacionadas con la prestación de productos y servicios de X-tremo y
            </p>
          </li>
          <li data-list-text="d)">
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
                Terceros que participan con X-tremo para poder ofrecer sus productos y servicios en términos de lo establecido por la regulación aplicable en materia de Datos Personales.
            </p>
          </li>
        </ol>
        <p style="text-indent: 0pt;text-align: left;">
          <br />
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
            El Titular acepta que, al proporcionar sus Datos Personales, estos estarán sujetos a las transferencias mencionadas en el presente Aviso de Privacidad.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br />
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
            Le informamos que para las transferencias indicadas si usted no manifiesta su negativa, entenderemos que nos ha otorgado su consentimiento.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br />
        </p>
      </li>
      <li data-list-text="4.">
        <p style="padding-left: 41pt;text-indent: -18pt;text-align: left;">
            Como ejercer sus derechos ARCO (acceso, rectificación, cancelación y oposición).
        </p>
        <p style="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">
            Para poder dar trámite a cualquier solicitud de acceso, rectificación, cancelación, oposición, así como para limitar el uso / divulgación o para solicitar la revocación del consentimiento de sus datos personales, deberá dirigir un escrito en Atención a Datos Personales RH X-TREMO ubicado en: Av. Múnich 175, Col. Cuauhtémoc, San Nicolás de los Garza, Nuevo León, México, C.P. 66450, es la responsable de la base de datos en donde consta su información de Lunes a Viernes de las 9:00 a las 13:00 y de las 15:30 a las 18:00 horas, en días hábiles, según corresponda. También puede realizarlo de forma electrónica enviando su solicitud al correo
        </p>
        <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">
            derechosarco@axtel.com.mx.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br />
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
            Toda solicitud para Ejercer los derechos ARCO ya sea en forma física o electrónica, deberá acompañarse de lo siguiente:
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br />
        </p>
        <ol id="l5">
          <li data-list-text="a)">
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
                El nombre del Titular, domicilio y/o cualquier otro medio para comunicarle la respuesta a su solicitud;
            </p>
          </li>
          <li data-list-text="b)">
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
                Los documentos que acrediten la identidad o, en su caso, la representación legal del Titular;
            </p>
          </li>
          <li data-list-text="c)">
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
                La descripción clara y precisa de los Datos Personales respecto de los que se busca ejercer alguno de los derechos antes mencionados;
            </p>
          </li>
          <li data-list-text="d)">
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
                Cualquier otro elemento o documento que facilite la localización de los Datos del Titular, así como su clave de RFC y/o CURP para evitar cualquier homonimia.
            </p>
          </li>
          <li data-list-text="e)">
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
                Especificar claramente si la solicitud es de acceso, rectificación, cancelación, oposición; limitar uso / divulgación o revocación del consentimiento.
            </p>
          </li>
          <li data-list-text="f)">
            <p style="padding-left: 15pt;text-indent: -10pt;text-align: left;">
                El motivo de la solicitud y
            </p>
          </li>
          <li data-list-text="g)">
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
                Las modificaciones requeridas, en caso de que la solicitud sea para la rectificación de Datos Personales y aportar la documentación que sustente su petición.
            </p>
          </li>
        </ol>
        <p style="text-indent: 0pt;text-align: left;">
          <br />
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
            En caso de que la información proporcionada en la Solicitud ARCO sea insuficiente o errónea para atenderla, o bien no se acompañen los documentos necesarios para dar trámite a la misma, X-tremo requerirá al titular de los datos o su representante, dentro de los 5 días hábiles siguientes a la recepción de la Solicitud ARCO, por una única vez, que aporte los elementos o documentos necesarios para dar trámite a la misma. El titular de los datos o su representante contará con 10 días hábiles contados a partir del día siguiente de la recepción del requerimiento, para darle respuesta. X-tremo dará respuesta a la Solicitud ARCO con la determinación alcanzada, en un plazo de 20 días hábiles contados a partir de la recepción de la solicitud, o en caso de haberse solicitado información o documentos adicionales, en un plazo de 20 días hábiles contados a partir del día siguiente a la presentación de la respuesta al requerimiento.
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
            En caso de ser procedente la Solicitud ARCO, X-tremo hará efectiva la determinación alcanzada dentro de un plazo de 15 días hábiles contados a partir de que se comunique la respuesta al titular de los datos o su representante. Tratándose de Solicitudes ARCO sobre el derecho de acceso a datos personales, la entrega de estos se hará previa acreditación de la identidad del titular de los datos o su representante. X-tremo podrá ampliar los plazos para dar respuesta a una Solicitud ARCO, y/o para hacer efectiva la determinación alcanzada, por una sola vez, por periodos iguales a los señalados en cada caso, siempre que considere que las circunstancias del caso lo justifican. Ante tales supuestos, X-tremo le notificará al titular de los datos o su representante, la(s) circunstancia(s) que justifican la ampliación, dentro de cada uno de los plazos originales para dar respuesta o hacer efectiva la determinación alcanzada. Las respuestas a las Solicitudes ARCO se entregarán al titular de los datos o a su representante legal en copias simples o en archivo electrónico según el tipo y la cantidad de documentos de que se trate cada caso.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br />
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br />
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br />
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
            Para la revocación del consentimiento bastará que el titular presente su solicitud en el domicilio de X-tremo. X-tremo hará efectiva dicha solicitud, siempre y cuando no se trate de un dato personal necesario para la existencia, mantenimiento y cumplimiento de su relación jurídica con el titular de los datos.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br />
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
            X-tremo podrá negar el acceso, rectificación, cancelación u oposición al tratamiento de estos, en los siguientes supuestos:
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br />
        </p>
        <ul id="l6">
          <li data-list-text="•">
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
                Cuando el solicitante no sea el titular de los datos personales, o el representante legal no esté debidamente acreditado para ello;
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 12pt;text-indent: -7pt;text-align: left;">
                Cuando en su base de datos no se encuentren los datos personales del solicitante;
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 12pt;text-indent: -7pt;text-align: left;">
                Cuando se lesionen los derechos de un tercero;
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
                Cuando exista un impedimento legal, o la resolución de una autoridad competente que restrinja el acceso a los datos personales o que no permita la rectificación, cancelación u oposición de estos;
            </p>
          </li>
          <li data-list-text="•">
            <p style="padding-left: 12pt;text-indent: -7pt;text-align: left;">
                Cuando la rectificación, cancelación u oposición haya sido previamente realizada.
            </p>
          </li>
        </ul>
        <p style="text-indent: 0pt;text-align: left;">
          <br />
        </p>
      </li>
      <li data-list-text="5.">
        <p style="padding-left: 41pt;text-indent: -18pt;text-align: left;">
            Procedimiento para revocar su consentimiento para el uso de sus datos personales.
        </p>
        <p style="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">
            Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales. Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligación legal requiramos seguir tratando sus datos personales. Asimismo, usted deberá considerar que para ciertos fines, la revocación de su consentimiento implicará que no le podamos seguir prestando el servicio que nos solicitó, o la conclusión de su relación con nosotros. A efecto de que el Titular pueda manifestar su negativa al respecto, deberá dirigirse a X-tremo por medio del procedimiento establecido para el ejercicio de los Derechos ARCO señalado en el numeral anterior.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br />
        </p>
      </li>
      <li data-list-text="6.">
        <p style="padding-left: 41pt;text-indent: -18pt;text-align: left;">
            Procedimiento para limitar el uso o divulgación de su información personal.
        </p>
        <p style="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">
            Para dar cumplimiento con las finalidades del presente Aviso de Privacidad, X-tremo se compromete a contar con las medidas legales y de seguridad suficientes y necesarias para garantizar que sus Datos Personales permanezcan confidenciales y seguros.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br />
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
            A efecto de que el Titular pueda realizar solicitud para limitar el uso o divulgación, deberá dirigirse al X-tremo por medio del procedimiento establecido para el ejercicio de los Derechos ARCO señalado en el numeral 5.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br />
        </p>
      </li>
      <li data-list-text="7.">
        <p style="padding-left: 41pt;text-indent: -18pt;text-align: left;">
            El uso de tecnologías de rastreo en nuestro portal de Internet.
        </p>
        <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">
            Le informamos que en nuestra página de Internet utilizamos cookies, web beacons y otras tecnologías a través de las cuales es posible monitorear su comportamiento como usuario de Internet, así como brindarle un mejor servicio y experiencia de usuario al navegar en nuestra página.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br />
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
            El visitante puede configurar su navegador para aceptar o rechazar por defecto todas las cookies o web beacons o para recibir un aviso en pantalla de la recepción de cada cookie o web beacons y decidir en ese momento su implantación o no en su disco duro. Las cookies o web beacons usadas en los sitios web de X-tremo, no proporcionan referencias que permitan deducir el nombre y apellidos del visitante y no pueden leer datos de su disco duro ni incluir virus en sus textos.
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br />
        </p>
        <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
            En caso de que el Titular proporcione sus Datos a través de este medio, incluyendo el sitio web (Internet) de X-tremo, el Titular entiende, acepta y reconoce que:
        </p>
        <p style="text-indent: 0pt;text-align: left;">
          <br />
        </p>
        <ol id="l7">
          <li data-list-text="a)">
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
                El sitio web de X-tremo puede incluir enlaces a sitios web de terceros, que de accederse, ocasionará que se abandone el sitio web de X-tremo, por lo cual X-tremo no asume ninguna responsabilidad en relación con esos sitios web de terceros.
            </p>
            <p style="text-indent: 0pt;text-align: left;">
              <br />
            </p>
          </li>
          <li data-list-text="b)">
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
                El sitio web de X-tremo puede incluir enlaces a sitios que administran redes sociales, en cuyo caso el Titular acepta que al proporcionar cualquier tipo de información o Datos en dichos sitios, ocasionará que la misma pueda ser leída, vista, accedida, retransmitida y tratada por cualquier persona, y por lo tanto libera de cualquier responsabilidad a X-tremo.
            </p>
          </li>
        </ol>
        <p style="text-indent: 0pt;text-align: left;">
          <br />
        </p>
      </li>
      <li data-list-text="8.">
        <p style="padding-left: 41pt;text-indent: -18pt;text-align: left;">
            Cambios al aviso de privacidad.
        </p>
      </li>
    </ol>
    <p style="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">
        El presente Aviso de Privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras prácticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas. Es nuestro compromiso mantenerlo informado sobre los cambios que pueda sufrir el presente Aviso de Privacidad Integral, a través de nuestra página de internet en: https://www.axtelcorp.mx/aviso-de-privacidad, https://www.alestra.mx/aviso-de-privacidad y https://www.xtremointernet.mx/legales/aviso-de-privacidad
    </p>
    <p style="text-indent: 0pt;text-align: left;">
      <br />
    </p>
    <h2 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
        Última actualización: 01 de enero de 2024.
    </h2>
  </body>
</html>
`;
