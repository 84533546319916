export const termsHtmlContent = `
<!DOCTYPE html PUBLIC "-//W3C//DTD HTML 4.01 Transitional//EN" "http://www.w3.org/TR/html4/loose.dtd">
<html>
  <head>
    <title></title>
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
  </head>
  <body>
    <div style="page-break-before:always; page-break-after:always">
      <div>
        <p style="text-align: center;">
          <b>AXE REDES E INFRAESTRUCTURA S.A. de C.V. <br />
          </b>
        </p>
        <p style="text-align: center;">
            <b>
                Código de Prácticas Comerciales
                <br />
            </b>
        </p>
        <p>
          <b>
            Hogar y Negocios
            <br />
          </b>
        </p>
        <p>
          <b>
            Cobertura y factibilidad técnica.
            <br />
          </b>
        </p>
        <p>
            Toda contratación deberá estar dentro de la zona de cobertura de la red de X-tremo Internet, para lo que el cliente deberá realizar la validación de la dirección donde requiera el servicio, esto lo realiza al inicio del proceso de venta.
            <br />
        </p>
        <p>
            Cualquier solicitud que se encuentre fuera de la zona de cobertura no podrá ser procesada. La prestación del servicio dentro de la cobertura mostrada está sujeta también a la factibilidad técnica. En caso de que existan restricciones que impidan la instalación, incluso dentro de la zona de cobertura, el Cliente será informado.
            <br />
        </p>
        <p>
            Algunas restricciones que impiden la instalación del servicio y las cuales no están dentro del alcance de la instalación del proveedor son:
            <br />
        </p>
        <p>
          &#8226; Que no existan facilidades de acceso hacia y dentro del domicilio del cliente (ductos tapados, inexistentes o sin permisos para utilizarlos.
          <br />
          &#8226; Que la administración del desarrollo inmobiliario, comité vecinal, autoridad competente o las mismas políticas del desarrollo inmobiliario no otorguen los permisos para realizar dicha instalación.
          <br />
        </p>
        <p>
          <b>
            Requisitos y proceso de contratación.
            <br />
          </b>
        </p>
        <p>
            &#8226; Realizar validación de cobertura
            <br />
            &#8226; Elegir uno de los planes disponibles
            <br />
            &#8226; Leer términos y condiciones de la oferta
            <br />
            &#8226; Se requiere registrar una tarjeta crédito o débito para el cargo recurrente de la renta mensual del servicio.
            <br />
            &#8226; Aceptar contrato en línea.
            <br />
        </p>
        <p>
          <b>
            Instalación
            <br />
          </b>
        </p>
        <p>
            Durante el proceso de contratación, le ofreceremos los horarios disponibles para instalar, buscando en medida de lo posible que sea dentro de las siguientes 72 horas hábiles.
            <br />
        </p>
        <p>
            El Cliente podrá seleccionar opciones de fecha y hora de visita para instalación, según su conveniencia.
            <br />
        </p>
        <p>
            Se instalará el servicio y/o equipos al Cliente siempre y cuando sea factible técnicamente.
            <br />
        </p>
        <p>
          <b>
            Tarifas para el Servicio de Internet.
            <br />
          </b>
        </p>
        <p>
            Consulta de tarifas para los planes Hogar y Negocios:
            <br />
        </p>
        <p>
            https://www.xtremointernet.mx/
            <br />
        </p>
        <p>
            https://tarifas.ift.org.mx/ift_visor/ 
            <br />
        </p>
        <p>
            Los precios publicados al Cliente en la página web, incluyen el IVA.
            <br />
        </p>
        <p>
            El método de pago aceptado para el servicio de X-tremo Internet es: domiciliación a una tarjeta de crédito o débito.
            <br />
        </p>
        <p>
            Son Aceptadas todas las tarjetas Visa, MasterCard y American Express que sen emitidas en México .
            <br />
        </p>
        <p>
            La renta mensual se factura por ciclo mensual. No hay cargos por instalación o activación.
            <br />
        </p>
        <p>
            La Renta Mensual por el Servicio y Servicios Adicionales se facturarán por mes adelantado, se cobrará la primera renta del servicio el día que se ejecute la instalación y de forma recurrente, ese mismo día de cada mes será la fecha de corte y cobro a la tarjeta de crédito o débito.
            <br />
        </p>
        <p>
            La fecha límite de pago se establece 15 días después de la fecha de corte.
            <br />
        </p>
        <p>
          <b>
            Niveles y compromisos de calidad.
            <br />
          </b>
        </p>
        <p>
            Todos los planes se ofrecen con la misma velocidad de subida y de bajada. El servicio de internet para nuestros planes de Hogares y Negocios se entrega en capa 3, con IP pública y dinámica.
            <br />
        </p>
        <p>
            La velocidad de los planes se establece como la velocidad máxima que el Cliente puede experimentar y esta puede variar debido a varios factores: según la hora del día, si está conectado por WiFi o Cableado, el número de dispositivos o usuarios concurrentes, entre otros.
            <br />
         </p>
      </div>
    </div>
    <div style="page-break-before:always; page-break-after:always">
      <div>
        <p>
            Para la cobertura WiFi que se instala en el domicilio, cada plan tiene definido un número máximo de equipos (ruteadores) WiFi incluidos.
            <br />
        </p>
        <p>
            En el alcance de la instalación que esta incluida en la oferta, dichos equipos WiFi se conectan de forma inalámbrica, solo el primer equipo va con cable a la ONT o módem, el resto se instalaran de forma inalámbrica.
            <br />
        </p>
        <p>
            En el caso de requerirse que los equipos WiFi se instalen en forma cableada, se cobrará al cliente el costo del cableado interior, mismo que le será explicado y detallado por el técnico instalador.
            <br />
        </p>
        <p>
            Puede haber escenarios donde el Cliente ya cuente con equipo WiFi instalado en su casa y NO requiera ningún ruteador WiFi de los que le ofrece X-tremo Internet, en este escenario NO cambia el precio del plan contratado. Además es importante señalar que No se dejan al cliente equipos que ampara la oferta y que no se utilicen.
            <br />
        </p>
        <p>
            El Cliente podrá adquirir con un tercero e implementar los ruteadores, multiplexores, equipos de telecomunicaciones, accesorios, cableado interno y/o Equipo de WiFi, siempre y cuando dicho equipo esté debidamente homologado conforme a lo establecido en la LFTR, sin embargo, el Proveedor no podrá gestionarlos o monitorearlos como parte del servicio prestado y no podrá garantizar que éstos funcionen adecuadamente cuando sean adquiridos de un tercero. De acuerdo con lo anterior, si el Cliente no utiliza equipo y/o accesorios debidamente homologados y/o que sean compatibles con la red y equipos del Proveedor, éste último podrá optar por rescindir el Contrato.
            <br />
        </p>
        <p>
            En el caso que la red WiFi o la necesidad del Cliente requiera más ruteadores que los incluidos en el plan contratado, existirá la opción de contratarlos como Equipos Adicionales por una renta adicional al plan contratado.
            <br />
        </p>
        <p>
            Ruteadores adicionales para WiFi: La renta mensual por cada ruteador WiFi adicional a los incluidos en el plan contratado es de $89 pesos incluyendo el IVA.
            <br />
        </p>
        <p>
            Los ruteadores WiFi y la ONT (modem) son siempre propiedad de AXE REDES y el Cliente está obligado a regresarlos al terminar de su contrato de servicio.
            <br />
        </p>
        <p>
            Todos los ruteadores se instalan de forma inalámbrica y se conectan a la red eléctrica del Cliente.
            <br />
        </p>
        <p>
          <b>
            Cambio de Plan.
            <br />
          </b>
        </p>
        <p>
            Nuestros Clientes pueden cambiar de plan en cualquier momento, solo considerar que pudiera haber costos adicionales por equipos no incluidos en el nuevo plan. Esto se ajustará en el siguiente cargo mensual.
            <br />
        </p>
        <p>
            El cliente se compromete a regresar a AXE REDES los equipos que, en su caso, no sean necesarios para la prestación del servicio.  
            <br />
        </p>
        <p>
            En caso de no regresar los equipos, el Cliente deberá pagar el monto correspondiente a la renta adicional de los equipos mientras no los devuelva.
            <br />
        </p>
        <p>
          <b>
            <br />
          </b>
        </p>
        <p>
          <b>
            Portal de Clientesy Factura.
            <br />
          </b>
        </p>
        <p>
            En el Portal de Clientes dentro de nuestro sitio web www.xtremointernet.mx, el Cliente podrá consultar la oferta que tiene contratada, conocer el estado de su servicio y red de WiFi que le instalamos en el domicilio, podrá realizar una prueba de velocidad y obtener ayuda o soporte.
            <br />
        </p>
        <p>
            También en dicho portal podrá conocer el monto de su próximo pago, fecha de pago, histórico de pagos realizados, cambiar el método de pago y registrar los datos fiscales para su facturación.
            <br />
        </p>
        <p>
            La factura se genera cuando se hace el cargo del servicio a la tarjeta de crédito o débito.
            <br />
        </p>
        <p>
            Para emitir una factura con efectos fiscales, el cliente deberá contar con su constancia de situación fiscal al momento de generar su primera factura y seguir las instrucciones que se dan en el portal de clientes en la sección de pagos.
            <br />
        </p>
        <p>
            La fecha de corte corresponde al día en que el servicio se instala o entrega al cliente.
            <br />
        </p>
        <p>
            Le enviaremos mensajes y recordatorios del cargo a su tarjeta de crédito o débito a su correo electrónico y número de WhatsApp que tenemos registrado.
            <br />
        </p>
        <p>
            Si desea actualizar algún dato, no dude en solicitarlo al equipo de servicio al cliente a través de nuestra página Web www.xtremointernet.mx
            <br />
        </p>
        <p>
          <b>
            <br />
          </b>
        </p>
        <p>
          <b>
            <br />
          </b>
        </p>
        <p>
          <b>
            <br />
          </b>
        </p>
        <p>
          <b></b>
        </p>
      </div>
    </div>
    <div style="page-break-before:always; page-break-after:always">
      <div>
        <p>
          <b>
            Vigencia del Contrato y Cancelación
            <br />
          </b>
        </p>
        <p>
            No se contemplan plazos forzosos para el contrato de servicio de Internet, se podrá dar por terminado el contrato en cualquier momento, sin responsabilidad ni penalidad alguna, haciendo la solicitud a nuestro equipo de soporte al cliente por WhatsApp.
            <br />
        </p>
        <p>
            El proceso se completa y libera de responsabilidad al cliente de facturación, una vez que hayamos recolectado y constatado el buen estado de los equipos en el domicilio del Cliente.
            <br />
        </p>
        <p>
            La terminación del contrato no exime Cliente de cubrir los adeudos pendientes de los servicios efectivamente prestados y de realizar la devolución de los equipos. En caso de no devolverlos, el Cliente se obliga a pagar la tarifa por el costo de los equipos.
            <br />
        </p>
        <p>
          <br />
        </p>
        <p>
          <b>
            Política de uso justo para Internet.
            <br />
          </b>
        </p>
        <p>
            Al utilizar los servicios de transmisión de datos y acceso a Internet de AXE REDES, usted acepta y está de acuerdo en cumplir los términos de esta política.
            <br />
        </p>
        <p>
            El Servicio de Internet es comercializado para el uso razonable y justo de Internet, de acuerdo con el plan contratado, ya sea hogar o de negocios, así como con las tarifas registradas ante el Instituto Federal de Telecomunicaciones (www.ift.org.mx) y, por lo tanto, no puede ser utilizado con propósito comercial alguno. Esta prohibición incluye, de manera enunciativa y no limitativa, cualquier acto que implique la comercialización del servicio a terceros, sea en la modalidad de reventa o bien integrando el servicio de AXE REDES como componente de un producto o servicio distinto.
            <br />
        </p>
        <p>
            El servicio contratado en un plan de Hogar queda entendido como el uso normal para un solo hogar de acuerdo con las necesidades de sus miembros, sin fines de lucro ni especulación comercial. De igual forma, el uso del servicio en alguno de los planes de Negocios será aquél consistente con la actividad razonable del cliente y no podrá en momento alguno integrarse, directa o indirectamente, a un producto o servicio distinto.
            <br />
        </p>
        <p>
            Queda estrictamente prohibido utilizar el servicio de internet de AXE REDES de forma que incumpla o viole las leyes, regulaciones, códigos aplicables, así como usos y buenas costumbres.
            <br />
        </p>
        <p>
          <b>
            Medios de atención aclientes:
            <br />
          </b>
        </p>
        <p>
            Canal de WhatsApp +52 81 4168 7090 del Centro Integral de atención al Cliente y Portal  del Cliente disponible en www.xtremointernet.mx. Disponible las 24 (veinticuatro) horas del día los 365 (trescientos sesenta y cinco) días del año.
            <br />
        </p>
        <p>
          <b>
            Otros Plazos de losprocedimientos:
            <br />
          </b>
        </p>
        <p>
            AXE REDES emitirá mensualmente con al menos 10 (diez) días naturales de anticipación a la fecha límite de pago un resumen de los cargos que el Cliente se obliga a pagar, el cual le será enviado mediante correo electrónico y/o estará disponible en el portal del Cliente al que éste podrá acceder por medio de la página de internet https://mi.xtremointernet.mx/.
            <br />
        </p>
        <p>
            Para las labores de supervisión, cambio, actualización o mantenimiento del Equipo de Acceso, Equipo de WiFi y/o de la tecnología utilizada para prestar el Servicio, AXE REDES notificará previamente cuando menos 1 día hábil de anticipación al Cliente los horarios y fechas de dichas labores.
            <br />
        </p>
        <p>
            Reanudación del servicio después de una suspensión por falta de pago: Dentro de un periodo que no excederá de 72 (setenta y dos) horas posteriores al reporte de pago.
            <br />
        </p>
        <p>
            AXE REDES  tiene un plazo de 5 días naturales para cancelar los servicios adicionales que así solicite el Cliente.
            <br />
        </p>
        <p>
            AXE REDES en un plazo máximo de 5 (cinco) días hábiles deberá efectuar las investigaciones necesarias sobre los cargos que el Cliente objete, una vez que tenga dichos resultados se los informará al Cliente.
            <br />
        </p>
        <p>
            El plazo máximo para dar respuesta a una solicitud de soporte, queja, reparación o bonificación es de 48 hrs.
            <br />
        </p>
      </div>
    </div>
  </body>
</html>
`;
